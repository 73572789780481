
import Typed from 'typed.js'
import { mixins } from "vue-class-component";
import { Vue, Component } from "vue-property-decorator";
import { ValidationMixin } from "@/mixins/validation-mixin";

@Component
export default class Login extends mixins(Vue, ValidationMixin) {
  private userId = '';
  private userPwd = '';
  private displayAuth = false;

  created() {
    // MARK: 가입한 뒤
    if (this.$route.params.create) {
      const { message } = this.$route.params;
      this.successMessage(message);
    }

    // MARK: 비밀번호 변경한 뒤
    if (this.$route.params.reLogin) {
      const message = 'Password change success. Please re signin';
      this.successMessage(message);
    }
  }

  mounted() {
    new Typed('.typed', {
      strings: ['PANEL HUB'],
      typeSpeed: 50,
      showCursor: false,
    });
  }

  async login(): Promise<void> {
    this.proc(true);
    try {
      const { result, message, statusCode } = await this.$store.dispatch('signIn', { userId: this.userId, userPwd: this.userPwd });

      if(result) {
        this.proc(false);
        return this.$router.replace(
            { path: '/overview' },
            (to:any) => {
              to.params.login = true;
              to.params.message = message.join('\r\n')
            })
      } else {
        this.invalidMessages = message

        if (statusCode === 409) {
          this.displayAuth = true;
        } else {
          this.displayAuth = false;
        }

        if (statusCode === 999) {
          const msg = message.join('\r\n');
          this.errorMessage(msg);
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.proc(false);
  }

  async mailResend(): Promise<void> {
    this.proc(true);
    try {
      const { result, message } = await this.axios.post('user/join-verify-resend',{
        userId: this.userId,
        userPwd: this.userPwd,
      })
      if (result) {
        this.$notify({
          type: 'success',
          group: 'message',
          title: 'Success',
          text: (message as string[]).join('\r\n'),
        });
      }
    } catch (e) {
      console.log(e)
    }
    this.proc(false);
  }

}
